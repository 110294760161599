/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
import React, { useRef, useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { Link, useLocation, useHistory } from 'react-router-dom';
import { FiArrowLeft, FiEdit, FiFileText, FiLink, FiLogOut, FiXCircle, FiEdit3 } from 'react-icons/fi';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import { Container, Content, ContentArea, FormContainer, BackContainer, 
  ObservationContainer, FormModalContainer, FormModalTextAreaContainer, 
  Section, Association, FormContainerSearch } from './styles';

import api from '../../services/api';
import Modal from 'react-modal';

import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';

import LeftMenu from '../../components/LeftMenu';
import InputWithLabel from '../../components/InputWithLabel';
import InputCpf from '../../components/InputCpf';
import InputCep from '../../components/InputCep';
import Select from '../../components/Select';
import Button from '../../components/Button';
import TextArea from '../../components/TextArea';
// import Modal from '../../components/Modal';

interface ClientFormData {
  id: string;
  user_id: string;
  name: string;
  cpf: string;
  birth_date: Date;
  nit: string;
  rg: string;
  // password: string;
  phones: string;
  marital_status: string;
  email: string;
  address: string;
  city_id: string;
  cep: string;
  profession: string;
  process_type: string;
  competence: string;
  process_number: string;
  folder: string;
  observation: string;
}

interface City {
  id: string;
  name: string;
  uf: string;
}

interface Competence {
  id: string;
  name: string;
}

interface ProcessType {
  id: string;
  name: string;
}

interface User {
  id: string;
  name: string;
  email: string;
  avatar_url: string;
}

interface Client {
  id: string;
  name: string;
  cpf: string;
  birth_date: Date;
  nit: string;
  rg: string;
  // password: string;
  phones: string;
  marital_status: string;
  email: string;
  address: string;
  city_id: string;
  cep: string;
  profession: string;
  city: City;
  observation: string;
  active: string;
}

interface ClientProcess {
  id: string;
  client_id: string;
  folder: string;
  process_type_id: string;
  competence_id: string;
  process_number: string;
  client: Client;
}

interface Location {
  selectedClient: Client;
  caller: string;
}

interface AssociateFormData {
  client_id: string;
  client_name: string;
  vinculo: string;
  obs: string;
}

interface Associate {
  id: string;
  client_id: string;
  parent_client_id: string;
  vinculo: string;
  observation: string;
  client: Client;
}

interface ParentAssociate {
  id: string;
  client_id: string;
  parent_client: Client;
  vinculo: string;
  observation: string;
}

interface Activities {
  client_id: string;
  name: string;
  intimations: number;
  contact_clients: number;
  judicial_implantations: number;
  admin_implantations: number;
}

// Modal.setAppElement('#root');

const UpdateClient: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const formRefAssociate = useRef<FormHandles>(null);
  const location = useLocation();
  const { addToast } = useToast();
  const history = useHistory();

  const locationState: Location = location.state as Location;

  console.log(locationState);

  const user = localStorage.getItem('@Rastreio:user');

  const { selectedClient } = locationState;

  const [cities, setCities] = useState<City[]>([]);
  const [competences, setCompetences] = useState<Competence[]>([]);
  const [processTypes, setProcessTypes] = useState<ProcessType[]>([]);
  const [clientProcess, setClientProcess] = useState<ClientProcess[]>([]);
  const [client, setClient] = useState<Client>();
  const [show, setShow] = useState<boolean>(false);
  const [caller, setCaller] = useState<string>('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalLinkClientIsOpen, setModalLinkClientIsOpen] = useState(false);
  const [clientToAssociate, setClientToAssociate] = useState<Client | undefined>();
  const [associations, setAssociations] = useState<Associate[]>([]);
  const [clientNames, setClientNames] = useState<Client[]>([]);
  const [parentAssociations, setParentAssociations] = useState<ParentAssociate[]>([]);
  const [modalParentAssociationIsOpen, setModalParentAssociationIsOpen] = useState(false);
  const [colorWithContent, setColorWithContent] = useState('#272b44');
  const [colorViewParentAssociations, setColorViewParentAssociations] = useState('#272b44');
  const [activities, setActivities] = useState<Activities | undefined>();

  console.log('clientProcess**', clientProcess);
  console.log(clientToAssociate);

  function handleOpenModalLinkClient() {
    setModalLinkClientIsOpen(true);
  }

  function handleCloseModalLinkClient() {
    setModalLinkClientIsOpen(false);
  }

  function handleCloseModal() {
    setModalIsOpen(false);
  }

  /*
  const handleOpenModalParentAssociation = () => {
    api.get(`/client-association/client/${selectedClient.id}`).then((response) => {
      setParentAssociations(response.data);
      setModalParentAssociationIsOpen(true);
    });
  }
  */

  function handleCloseModalParentAssociation() {
    setModalParentAssociationIsOpen(false);
  }

  const customStyles = {
    content: {
      background: '#b2dbbf',
      top: '10%',
      left: '30%',
      right: '25%'
      // right: 'auto'
    }
  }

  useEffect(() => {
    api.get(`/clients/activities/${selectedClient.id}`).then((response) => {
      setActivities(response.data);
    });
  }, [selectedClient.id]);

  
  useEffect(() => {
    setCompetences(JSON.parse(localStorage.getItem("@Rastreio:competences")!));
    setProcessTypes(JSON.parse(localStorage.getItem("@Rastreio:process_types")!))
    if (locationState.caller) {
      setShow(true);
      setCaller(`/${locationState.caller}`);
    }
    if (selectedClient) {
      console.log(selectedClient);
      setClient(selectedClient);

      const birthDate: string = selectedClient.birth_date
        .toString()
        .substring(0, 10);

      formRef.current?.setData({ name: selectedClient.name });
      formRef.current?.setData({ address: selectedClient.address });
      formRef.current?.setData({ cpf: selectedClient.cpf });
      formRef.current?.setData({ nit: selectedClient.nit });

      if (birthDate !== '1900-01-01' && birthDate !== '1899-12-31') {
        formRef.current?.setData({ birth_date: birthDate });
      }

      formRef.current?.setData({ rg: selectedClient.rg });
      formRef.current?.setData({ phones: selectedClient.phones });
      formRef.current?.setData({
        marital_status: selectedClient.marital_status,
      });

      formRef.current?.setData({ email: selectedClient.email });
      formRef.current?.setData({ city_id: selectedClient.city_id });
      formRef.current?.setData({ cep: selectedClient.cep });
      formRef.current?.setData({ profession: selectedClient.profession });
      // formRef.current?.setData({ password: selectedClient.password });
      formRef.current?.setData({ observation: selectedClient.observation });

      api.get(`/client-association/parent_client/${selectedClient.id}`).then((response) => {
        setAssociations(response.data);
        if (response.data.length > 0) {
          setColorWithContent('#FF0000');
        }
      });

      api.get(`/client-association/client/${selectedClient.id}`).then((response) => {
        setParentAssociations(response.data);
        if (response.data.length > 0) {
          setColorViewParentAssociations('#FF0000');
        }
      });

      api
        .get(`/client-process/client/${selectedClient.id}`)
        .then((response) => {
          setClientProcess(response.data);
          if (response.data[0]) {
            formRef.current?.setData({ folder: response.data[0].folder });
            formRef.current?.setData({
              process_type: response.data[0].process_type_id,
            });
            formRef.current?.setData({
              competence: response.data[0].competence_id,
            });
            formRef.current?.setData({
              process_number: response.data[0].process_number,
            });
          }
          if (response.data.length > 1) {
            const folders: string[] = [];
            console.log(response.data);
            response.data.forEach((repo: any) => {
              folders.push(repo.folder);
            });
            formRef.current?.setData({ folder: folders.join(", ") });
          }
        });
    }
  }, [selectedClient, locationState.caller]);

  useEffect(() => {
    api.get('/clients').then((response) => {
      setClientNames(response.data);
    });
  }, []);

  useEffect(() => {
    api.get('/cities').then((response) => {
      setCities(response.data);
    });
  }, []);

  /*
  useEffect(() => {
    api.get('/competences').then((response) => {
      setCompetences(response.data);
    });
  }, []);
  */

  /*
  useEffect(() => {
    api.get('/process-types').then((response) => {
      setProcessTypes(response.data);
    });
  }, []);
  */

  /*
  useEffect(() => {
    api.get(`/client-association/parent_client/${selectedClient.id}`).then((response) => {
      setAssociations(response.data);
    });
  }, [selectedClient.id]);
  */
  
  const handleSubmitFormAssociate = useCallback(
    async (data: AssociateFormData) => {

      try {
        console.log(data);

        formRefAssociate.current?.setErrors({});

        const schema = Yup.object().shape({
          vinculo: Yup.string().required('Vínculo obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (clientToAssociate) {
          data.client_id = clientToAssociate?.id!;

          await api.post('/client-association', {
            parent_client_id: selectedClient.id,
            client_id: clientToAssociate?.id,
            vinculo: data.vinculo,
            observation: data.obs,
          });

          await api.get(
            `/client-association/parent_client/${selectedClient.id}`,
          ).then((response) => {
            setAssociations(response.data);
          });

          /*
          setAssociations(current => [...current, {
            client_name: clientToAssociate?.name!, 
            link: data.vinculo, observation: data.obs
          }]);
          */
        }
        else {
          alert('Selecione o cliente a ser vinculado');
        }
        
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefAssociate.current?.setErrors(errors);

          return;
        }
      }
      
    }, [clientToAssociate, selectedClient.id]
  )

  const handleSubmit = useCallback(
    async (data: ClientFormData) => {
      try {
        if (user) {
          formRef.current?.setErrors({});

          const schema = Yup.object().shape({
            name: Yup.string().required('Nome obrigatório'),
            cpf: Yup.string().required('CPF obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          // eslint-disable-next-line no-param-reassign
          data.user_id = JSON.parse(user).id;
          data.id = selectedClient.id;

          console.log('formData', data);

          if (data.folder) {
            const schema3 = Yup.object().shape({
              folder: Yup.string().required('Pasta já utilizada'),
            });

            await api.get(`/client-process/folder/${data.folder}`).then(async (response) => {
              if (response.data) {
                const retorno: ClientProcess  = response.data as ClientProcess;
                console.log(data.folder);
                console.log(retorno.folder);

                if (clientProcess[0].folder !== retorno.folder) {
                  data.folder = '';

                  addToast({
                    type: 'error',
                    title: `Pasta já utilizada`,
                    description: `Esta pasta já está em uso pelo cliente ${retorno.client.name}`,
                  });

                  await schema3.validate(data, {
                    abortEarly: false,
                  });
                }
              }
            });
          }

          // Verifica se o CPF que está sendo informado já está em uso
          const schemaExistsCpf = Yup.object().shape({
            cpf: Yup.string().required('CPF já utilizado'),
          });

          await api.get(`/clients/cpf/${data.cpf}`).then(async (response) => {
            const clientFound: ClientFormData = response.data as ClientFormData

            if (clientFound) {
              if (selectedClient.cpf !== clientFound.cpf) {
                data.cpf = '';

                alert(`Este CPF já está em uso pelo cliente ${clientFound.name}`);

                await schemaExistsCpf.validate(data, {
                  abortEarly: false,
                });
              }
            }
          });

          
          await api.put('/clients', data);

          // console.log('clientProcess', clientProcess);

          const areThereAnyCommas = data.folder.includes(',');

          if (clientProcess.length > 0) {
            if (!areThereAnyCommas) {
              await api.put('/client-process', {
                id: clientProcess[0].id,
                client_id: data.id,
                folder: data.folder,
                process_type_id: data.process_type,
                competence_id:
                  data.competence.length > 0 ? data.competence : null,
                process_number: data.process_number,
              });
            }
          } else if (data.folder) {
            const schema2 = Yup.object().shape({
              folder: Yup.string().required('Pasta obrigatória'),
              process_type: Yup.string().required(
                'Tipo de processo obrigatório',
              ),
              competence: Yup.string().required('Competência obrigatória'),
            });

            await schema2.validate(data, {
              abortEarly: false,
            });

            if (!areThereAnyCommas) {
              console.log('client_id', selectedClient.id);
              await api.post('/client-process', {
                client_id: selectedClient.id,
                folder: data.folder,
                process_type_id: data.process_type,
                competence_id:
                  data.competence.length > 0 ? data.competence : null,
                process_number: data.process_number,
              });
            }
          }

          await api
            .get(`/clients/cpf/${selectedClient.cpf}`)
            .then((response) => {
              setClient(response.data);
          });
          
          alert('Cadastro atualizado');

          addToast({
            type: 'success',
            title: 'Cadastro atualizado!',
            description: 'Dados do cliente atualizados',
          });
          
        }
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na alteração',
          description: 'Ocorreu um erro ao alterar cliente.',
        });
      }
    },
    [addToast, clientProcess, selectedClient.cpf, selectedClient.id, user],
  );

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const toInputUppercase = (e: any) => {
    e.target.value = `${e.target.value}`.toUpperCase();
  };

  const handleDeleteAssociation = useCallback(
    async (association: Associate) => {
      // eslint-disable-next-line no-restricted-globals
      const c = confirm('Deseja excluir?');
      if (c === true) {
        console.log('Attendance id', association.id);
        await api
          .delete(`/client-association/${association.id}`)
          .then((response) => {
            setAssociations((asso) =>
              asso.filter((_asso) => _asso.id !== association.id),
            );
          });
      }
    },
    [],
  );

  const handleAttendance = useCallback(() => {
    if (selectedClient) {
      api
        .get(`/client-process/client/${selectedClient.id}`)
        .then((response) => {
          console.log('resultado', response.data);
          if (response.data.length > 1) {
            history.push('/client-process', {
              selectedClient,
            });
          } else {
            history.push('/process-attendance', {
              process: response.data[0],
              selectedClient,
            });
          }
        });
    }
    },[history, selectedClient]
  );


  function callIntimationPage() {
    // Navega para a página com o parâmetro client_id
    history.push(`/intimation?client_id=${selectedClient.id}`);
  };

  function callContactClientsPage() {
    history.push(`/contact-client-list?client_id=${selectedClient.id}`)
  }

  function callJudicialImplantationPage() {
    history.push(`/judicial-implantation-list?client_id=${selectedClient.id}`)
  }

  function callAdminImplantationPage() {
    history.push(`/admin-implantation-list?client_id=${selectedClient.id}`)
  }


  return (
    <Container>
      <Content>
        <LeftMenu />
        <ContentArea>
          <h1>{selectedClient != null && selectedClient.name}</h1>
          <FormContainerSearch>
            {activities?.intimations !== undefined && activities?.intimations > 0 && (
              <Button type="button" onClick={callIntimationPage}>Prazos</Button>
            )}

            {activities?.contact_clients !== undefined && activities?.contact_clients > 0 && (
              <Button type="button" onClick={callContactClientsPage}>Contactar Clientes</Button>
            )}

            {activities?.judicial_implantations !== undefined && activities?.judicial_implantations > 0 && (
              <Button type="button" onClick={callJudicialImplantationPage}>Imp. Judicial</Button>
            )}

            {activities?.admin_implantations !== undefined && activities?.admin_implantations > 0 && (
              <Button type="button" onClick={callAdminImplantationPage}>Imp. Administrativa</Button>
            )}
            
          </FormContainerSearch>
          <br />
          <div>
            <Link
              to={{
                pathname: '/client-process',
                state: selectedClient,
              }}
            >
              <FiEdit size={30} title="Gerenciar Processos" />
            </Link>

            <Link
              to={{
                pathname: '/word-document',
                state: {
                  client,
                  city: cities.find((x) => x.id === selectedClient.city_id),
                },
              }}
            >
              <FiFileText size={30} title="Gerar Documento" style={{marginLeft: 15}} />
            </Link>

            <FiLink size={30} color={colorWithContent} title="Vincular Cliente" style={{marginLeft: 15}} onClick={handleOpenModalLinkClient} />

            {/* <FiSearch size={30} color={colorViewParentAssociations} title="Verificar Vínculos" style={{marginLeft: 15}} onClick={handleOpenModalParentAssociation} /> */}

            <FiEdit3 size={30} color={colorViewParentAssociations} title="Andamento de Processo" style={{marginLeft: 15}} onClick={handleAttendance} />

            {/* 
            <FiBellOff size={30} title="Notificações" style={{marginLeft: 15}} onClick={handleOpenModal}/> */}
            
            <Modal
              isOpen={modalLinkClientIsOpen}
              ariaHideApp={false}
              onRequestClose={handleCloseModalLinkClient}
              style={customStyles}
            >
              <FormModalContainer>
                <h2>Vincular Cliente</h2>
                <div style={{display: 'inline-block', textAlign: 'right', width: '100%'}}>
                  {/* <FiSave size={30} title="Salvar" />
                  &nbsp;&nbsp;&nbsp;&nbsp; */}
                  <FiLogOut size={30} title="Fechar" onClick={handleCloseModalLinkClient} />
                </div>

              </FormModalContainer>
              

              <Form ref={formRefAssociate} onSubmit={handleSubmitFormAssociate}>
                <FormModalContainer>
                    <div>
                      <Autocomplete
                        id="client_name"
                        size="medium"
                        style={{  marginTop: 30 }}
                        options={clientNames}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newValue) => {
                          setClientToAssociate(newValue!);
                        }}
                        renderInput={(params) => (
                          <TextField
                            style={{ backgroundColor: '#fff', padding: '0' }}
                            {...params}
                            placeholder="Digite ou selecione o Cliente..."
                            variant="outlined"
                          />
                        )}
                      />
                    </div>

                    <InputWithLabel
                      name="vinculo"
                      label="Vínculo"
                    />
                </FormModalContainer>

                <br />

                <FormModalTextAreaContainer>
                  <TextArea
                    name="obs"
                    label="Observação"
                    rows={2}
                  />
                </FormModalTextAreaContainer>

                <FormModalContainer>
                  <button className='button-add'>Adicionar</button>
                </FormModalContainer>
              </Form>

              <Section>
                <Association>
                  <div>
                    <strong><b>Cliente</b></strong>
                  </div>
                  <div>
                    <strong><b>Vínculo</b></strong>
                  </div>
                  <div>
                    <strong><b>Observação</b></strong>
                  </div>
                  <div>
                    &nbsp;
                  </div>
                </Association>
              </Section>

              <Section>
              {associations.map((asso) => (
                asso.client.active === 'S' && (
                  <Association key={asso.id}>
                    <div>
                      <strong>
                        <b>{asso.client.name}</b>
                      </strong>
                    </div>
                    <div>
                      <strong>{asso.vinculo}</strong>
                    </div>
                    <div>
                      <strong>{asso.observation}</strong>
                    </div>
                    <div>
                      <FiXCircle
                        onClick={() => {
                          asso.id && handleDeleteAssociation(asso);
                        }}
                        title="Excluir"
                      />
                    </div>
                  </Association>
                )
              ))}
              </Section>
            </Modal>


            <Modal
              isOpen={modalParentAssociationIsOpen}
              ariaHideApp={false}
              onRequestClose={handleCloseModalParentAssociation}
              style={customStyles}
            >

              <FormModalContainer>
                <h2>Vínculos</h2>
                <div style={{display: 'inline-block', textAlign: 'right', width: '100%'}}>
                  <FiLogOut size={30} title="Fechar" onClick={handleCloseModalParentAssociation} />
                </div>

              </FormModalContainer>
              
              <Section>
                <Association>
                  <div>
                    <strong><b>Vinculado com</b></strong>
                  </div>
                  <div>
                    <strong><b>Vínculo</b></strong>
                  </div>
                  <div>
                    <strong><b>Observação</b></strong>
                  </div>
                </Association>
              </Section>

              <Section>
              {parentAssociations.map((parent_asso) => (
                <Association key={parent_asso.id}>
                  <div>
                    <strong>
                      <b>{parent_asso.parent_client.name}</b>
                    </strong>
                  </div>
                  <div>
                    <strong>{parent_asso.vinculo}</strong>
                  </div>
                  <div>
                    <strong>{parent_asso.observation}</strong>
                  </div>
                </Association>
              ))}
              </Section>
            </Modal>

            <Modal
              isOpen={modalIsOpen}
              ariaHideApp={false}
              onRequestClose={handleCloseModal}
              style={customStyles}
            >
              <h2>Notificações</h2>
              <button onClick={handleCloseModal}>Fechar</button>
              <div>Eu sou um modal</div>
            </Modal>
            
          </div>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <br/>
            <BackContainer>
              <div className={show ? 'showDiv' : 'hideDiv'}>
                <Link
                  to={{
                    pathname: `${caller}`,
                  }}
                >
                  <FiArrowLeft size={30} title="Cadastro do Cliente" />
                </Link>
              </div>
            </BackContainer>

            {/* <FormContainer>
              <Link
                to={{
                  pathname: '/client-process',
                  state: selectedClient,
                }}
              >
                <label style={{ fontSize: 20, fontWeight: 'bold' }}>
                  Gerenciar Processos
                </label>
              </Link>

              
              <Link
                to={{
                  pathname: '/word-document',
                  state: {
                    client,
                    city: cities.find((x) => x.id === selectedClient.city_id),
                  },
                }}
              >
                <label style={{ fontSize: 20, fontWeight: 'bold' }}>
                  Gerar Documento
                </label>
              </Link>
            </FormContainer>
            <br /><br /> */}

            <FormContainer>
              <InputWithLabel
                name="name"
                label="Nome"
                onChange={toInputUppercase}
              />
              <InputCpf name="cpf" label="CPF" />
              
              <InputWithLabel name="folder" label="Pasta" />
              <InputWithLabel name="rg" label="RG" />
              <InputWithLabel name="nit" label="NIT" />
              <InputWithLabel
                name="birth_date"
                label="Data de Nascimento"
                type="date"
              />
              {/* <InputWithLabel name="password" label="Senha" /> */}

              <InputWithLabel name="phones" label="Telefones" />

              <Select
                id="marital_status"
                name="marital_status"
                label="Estado Civil"
                initialLabel="Selecione o Estado Civil"
                options={[
                  { value: 'solteiro(a)', label: 'solteiro(a)' },
                  { value: 'casado(a)', label: 'casado(a)' },
                  { value: 'divorciado(a)', label: 'divorciado(a)' },
                  { value: 'viúvo(a)', label: 'viúvo(a)' },
                  { value: 'união estável', label: 'união estável' },
                  { value: 'separado(a)', label: 'separado(a)' },
                ]}
              />

              <InputWithLabel
                name="email"
                label="E-mail"
              />

              <InputWithLabel
                name="address"
                label="Endereço"
              />

              <Select
                id="city_id"
                name="city_id"
                label="Cidade"
                initialLabel="Selecione a Cidade"
                options={cities.map((city) => ({
                  value: city.id,
                  label: city.name,
                }))}
              />

              <InputCep name="cep" label="CEP" maxLength={9} />
              <InputWithLabel name="profession" label="Profissão" />

              <Select
                name="process_type"
                label="Tipo de Processo"
                initialLabel="Selecione o Tipo do Processo"
                options={processTypes.map((processType) => ({
                  value: processType.id,
                  label: processType.name,
                }))}
              />

              <Select
                name="competence"
                label="Competência"
                initialLabel="Selecione a Competência"
                options={competences.map((competence) => ({
                  value: competence.id,
                  label: competence.name,
                }))}
              />

              <InputWithLabel name="process_number" label="Nº do Processo" />
            </FormContainer>

            <ObservationContainer>
              <TextArea
                name="observation"
                label="Observações"
                rows={20}
                cols={130}
              />
            </ObservationContainer>

            <Button type="submit">Salvar Alterações</Button>
          </Form>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default UpdateClient;
